.title{
    font-size: 3vw;
    font-family: pretendard;
    color: #202020;
    font-weight: 600;
    text-align: center;
}

.Portfolio{
    margin: 0 auto;
}

.portImg{
    width: 100%;
    height: 100%;
    position: relative;
}

.textZone{
    position: absolute;
    transform: translate(32px, -152px);
    width: 27vw;
    font-family: pretendard;
    display: none;
}
.portTitle{
    font-size: 1.3rem;
    font-weight: 700;
    color: #FFFFFF;
}
.portText{
    color: #E9E9E9;
    font-size: 1rem;
    line-height: 1.4rem;
}

@media screen and (max-width: 1024px) {
    .textZone{
        width: 92vw;
    }
}

@media screen and (max-width: 479px) {
    .portImg{
        width: 97vw;
        height: 34vh;
    }
    .portTitle{
        font-size: 1.125rem;
    }
    .portText{
        font-size: 0.8rem;
    }
    .textZone{
        width: 85vw;
        transform: translate(27px, -166px);
    }
}


