@font-face {
  font-family: "pretendard";
  src: url(/public/font/Pretendard-Regular.otf);
}
@font-face {
  font-family: "jost";
  src: url(/public/font/Jost-900-Black.ttf);
}
body{
  margin: 0;
  height: 100%;
  width: 100vw;
}

.App{
  width: 100vw;
  height: auto;
  background-image: url(/public/img/background.png);
  background-attachment: fixed;
  background-repeat: no-repeat;
  margin: 0 auto;
  background-position: center;
  background-size: cover;
}
.adiotInfo{
  background-color: white;
}

@media screen and (max-width: 1024px) {
  .App{
    background-image: none; 
  }
}


