.identity,.workframe,.service{
    margin: 0 auto;
    width: 62vw;
}

.identity{
    display: grid;
    grid-template-columns: 20% 80%;
    padding: 150px 0;
}

.identityImg{
    margin-top: 1rem;
    font-family: pretendard;
    font-weight: 600;
    color: #202020;
    font-size: 3vw;
}

.identityZone{
    text-align: right;
}
.indentityText{
    width: calc(100%/4);
    font-family: pretendard;
    display: inline-block;
    margin: 2rem 1.2rem;
    text-align: left;
}
.Title{
    font-size: 1.3rem;
    font-weight: 700;
    color: #303030;
    margin: 0.4rem 0;
}
.important{
    color: #0C75F5;
}
.text{
    color: #707070;
    font-weight: 500;
    font-size: 0.94rem;
}

.service{
    display: grid;
    grid-template-columns: 50% 50%;
    padding-bottom: 9.7rem;
    position: relative;
}

.itImg{
    position: relative;
    width: 95%;
}
.itText{
    position: absolute;
    transform: translate(1vw, -3vw);
    z-index: 1;
    color: #FFFFFF;
    font-family: pretendard;
    font-size: 1vw;
}
.serviceTitle{
    font-family: pretendard;
    font-weight: 600;
    font-size: 3vw;
    margin-top: 0;
}
.company{
    color: #0097FF;
}
.serviceText{
    font-family: pretendard;
    font-size: 1vw;
    color: #202020;
    line-height: 1.4rem;
    width: 100%;
}
.serviceCard{
    display: grid;
    align-content: space-around;
    align-items: center;
}
.serviceIcon1{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
}
.serviceIcon2{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    margin-bottom: 1rem;
}
.serviceIcon3{
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}
.serviceContent{
    width: 100%;
    padding-left: 6%;
    box-sizing: border-box;
}
.workframe{
    padding-bottom: 150px;
}
.frameTitle{
    font-size: 2rem;
    font-weight: 600;
    width: 13rem;
    padding-bottom: 0.4rem;
    color: #202020;
    font-family: pretendard;
    border-bottom: 2px solid #202020;
}
.frameImg{
    width: 100%;
}
@media screen and (max-width: 1024px) {
    .identity,.workframe{
        width: 100vw;
        padding-bottom: 64px;
    }
    .identity{
        padding: 64px 16px;
        box-sizing: border-box;
    }
    .service{ 
        width: 100vw;
        padding: 32px 16px 64px;
        box-sizing: border-box;
    }
    
    .serviceText{
        font-size: 1rem;
    }
    .workframe{
        padding: 24px 16px;
        box-sizing: border-box;
    }
    .serviceCard{
        align-content: center;
    }
    .serviceIcon2{
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 767px) {
    .identityImg{
        font-size: 5vw;
    }
    .identity{
        display: flex;
        flex-direction: column;
    }
    .indentityText{
        width: 38%;
        display: block;
        margin-right: 10%;
        margin-left: 0;
        float: left;
    }
    .itText{
        transform: translate(1vw, -4vw);
    }
    .serviceTitle{
        font-size: 5vw;
    }
}

@media screen and (max-width: 479px) {
c
    .identity, .service{
        display: block;
    }
    
    .identityImg{
        margin-top: 0;
        margin-bottom: 32px;
        font-size: 1.875rem;
    }
    .indentityText{
        display: block;
        width: 100%;
        margin-top: 2em;
        margin-bottom: 0;
    }
    .text{
        font-size: 0.94rem;
    }
    .service{
        display: flex;
        flex-direction: column-reverse;
    }
    .itImg{
        width: 44vw;
    }
    .serviceCard{
        justify-items: center;
    }
    .itText{
        transform: translate(2vw, -7vw);
    }
    .serviceContent{
        position: revert;
        margin-top: 24px;
        width: 100%;
        padding-left: 0;
    }
    .serviceTitle{
        margin-bottom: 24px;
        font-size: 1.875rem;
    }
    .serviceText{
        width: 100%;
        font-size: 0.9rem;
    }
    .frameTitle{
        font-size: 1.625rem;
    }
}