@charset 'UTF-8';
/* Slider */
/* .slick-loading .slick-list
{
    background: #fff url('./ajax-loader.gif') center center no-repeat;
} */

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 3rem;
    height: 3rem;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: black;
    border: 2px solid #DFDFDF;
    border-radius: 50%;
    outline: none;
    background: white;
    z-index: 10;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: black;
    outline: none;
    background: white;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 30px;
    line-height: 1;

    position: absolute;
    transform: translate(-4px, -18px);

    opacity: .75;
    color: #2A2D53;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: 29%;
}
[dir='rtl'] .slick-prev
{
    right: -49.5rem;
    left: auto;
}
.slick-prev:before
{
    content: url(/public/img/icon/left.png);
}
[dir='rtl'] .slick-prev:before
{
    content: url(/public/img/icon/right.png);
}

.slick-next
{
    right: 29%;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -49.5rem;
}
.slick-next:before
{
    content: url(/public/img/icon/right.png);
}
[dir='rtl'] .slick-next:before
{
    content: url(/public/img/icon/left.png);
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 24px;
    height: 24px;

    content: url(/public/img/icon/slideDot.png);
    text-align: center;

    opacity: 0.25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

@media screen and (max-width: 1024px) {
    .slick-prev, .slick-next{
        width: 2.5rem;
        height: 2.5rem;
    }
    .slick-prev{
        left: 3%;
        top: 104%;
    }
    .slick-next{
        top: 104%;
        right: 3%;
    }
}


@media screen and (min-width: 300px) and (max-width: 479px) {
    .slick-prev, .slick-next{
        width: 2rem;
        height: 2rem;
    }
    .slick-prev{
        left: 20px;
    }
    .slick-next{
        right: 20px;
    }
}