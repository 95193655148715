.headerBack{
    position: relative;
    width: 62vw;
    min-height: 93vh;
    margin: 0 auto;
}

.home{
    position: absolute;
    top: 25px;
    left: -17vw;
    z-index: 1;
    background-color: transparent;
}
.headerDiv{
    margin: 10em auto;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    position: absolute;
    z-index: 1;
    background-color: transparent;
}
.logoText, .moveLogo{
    width: 100%;
}
@media screen and (max-width: 1024px) {
    .headerBack{
        width: 100vw;
        min-height: 100vh;
        background-image: url(/public/img/tabletBack.png);
        background-position: center;
        background-size: cover;
    }
    .home{
        top: 5%;
        left: 37%;
        width: 22vw;
    }
    .headerDiv{
        padding: 8em 0;
        margin: 0;
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
    }
    .left, .right{
        width: 100vw;
        box-sizing: border-box;
        padding: 0px 16px;
    }
    .left{
        text-align: left;
    }
    .right{
        text-align: right;
    }
    .logoText, .moveLogo{
        width: 52%;
    }
      
}

@media screen and (max-width: 767px) {
    .headerDiv{
        padding: 4em 0;
    }
    .headerBack{
        min-height: 60vh;
        background-image: url(/public/img/767Back.png);
        background-position: center;
        background-size: cover;
    }
    .logoText{
        width: 46%;
    }
    .moveLogo{
        width: 40%;
    } 
}

@media screen and (max-width: 479px) {
    .headerBack{
        min-height: 50vh;
        background-image: url(/public/img/mobileBack.png);
    }
    .headerDiv{
        padding: 4em 0;
    }
    .home{
        left: 33%;
        width: 36vw;
    }
    .logoText{
        width: 51%;
    }
    .moveLogo{
        width: 46%;
    }
    
}