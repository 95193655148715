.location{
    margin: 0 auto;
    font-family: pretendard;
    padding: 160px 0;
    width: 62vw;
}
.locationTitle{
    font-size: 2vw;
    color: #202020;
    font-weight: 600;
}

.maps{
    width: 100%;
    height: 32vh;
    margin: 2.5rem 0;
}
.maps div{
    height: 100%;
}

.company{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.companyInfo{
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: start;
}

.companyInfo h4{
    font-size: 1.5rem;
    color: #000000;
    font-weight: 700;
    margin-top: 16px;
}
.title{
    font-size: 1rem;
    font-weight: 700;
    color: #202020;
    margin-bottom: 0;
}
.text{
    font-size: 1rem;
    font-weight: 400;
    color: #202020;
    margin-top: 0.3rem;
    width: 62%;
}


@media screen and (max-width: 1024px) {
    .location{
        width: 100vw;
        box-sizing: border-box;
        padding: 64px 16px;
    }
    .locationTitle{
        font-size: 1.8125rem;
        margin: 0;
    }
    .company{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .companyInfo{
        display: flex;
        flex-direction: column;
    }
    .companyInfo h4{
        margin-bottom: 10px;
    }
    .infoBox{
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
    }
    .text{
        width: 100vw;
    }
}


@media screen and (max-width: 479px) {
    .locationTitle{
        font-size: 1.625rem;
    }
    .maps{
        margin: 24px 0;
        height: 28vh;
    }
    .companyInfo h4{
        margin-bottom: 0;
        font-size: 1.25rem;
    }
    
}