
.footer{
    width: 62vw;
    min-height: 40vh;
    text-align: center;
    margin: 0 auto;
}
.footer h3{
    font-family: jost;
    font-size: 2vw;
    color: #303030;
    padding-top: 4em;
    margin: 0;
    padding-bottom: 2em;
    box-sizing: border-box;
}
.footerText{
    font-family: pretendard;
    font-size: 1.1rem;
    color: #303030;
    line-height: 1.7rem;
    font-weight: 600;
    margin: 7vh auto;
    width: 100%;
}
.email{
    border: none;
    width: 22vw;
    height: 5vh;
    border-radius: 1.7rem;
    background-color: #ffffff;
    text-align: center;
    font-size: 1.25rem;
    color: #303030;
    font-family: pretendard;
    margin-bottom: 10rem;
}
.footerLogo{
    font-family: pretendard;
    font-size: 0.9rem;
    color: #505050;
    padding: 16px auto;
    margin: 0;
    padding-bottom: 10px;
}

@media screen and (max-width: 1024px) {
    .footer{
        width: 100vw;
        min-height: 47vh;
        background-image: url(/public/img/tabletBack.png);
        background-position: center;
        background-size: cover;
    }
    .footer h3{
        font-size: 1.875rem;
    }
    .footerText{
        font-size: 1rem;
        margin: 4vh auto;
        width: 100%;
        padding: 0 8% 24px;
        box-sizing: border-box;
        margin: 0 auto;
    }
    .email{
        width: 62%;
        font-size: 1.125rem;
        margin-bottom: 64px;
        height: 5vh;
    }
}

@media screen and (max-width: 767px) {
    .footer{
        background-image: url(/public/img/767Back.png);
    }
}


@media screen and (max-width: 479px) {
    .footer{
        background-image: url(/public/img/mobileBack.png);
    }
    .footer h3{
        font-size: 1.875rem;
    }
}